.App {
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

img {
  width: 115px;
  height: 115px;
  border-radius: 15px;
}

p {
  text-align: left;
  margin: 20;
}

h2 {
  margin: 0px 0px 20px 0px;
}

h4 {
  margin: 0px 0px 20px 0px;
  align-items: flex-start;
  font-size: medium;
}